<template>
  <section id="inquiry-form" class="mb-5">
  <h2 class="fw-bolder my-3 fs-1">お問い合わせ</h2>
  <p class="text-center mx-2">
    お返事は、原則3営業日以内にさせていただきます。<br>
  </p>  
  <form class="shadow shadow-sm border p-3 mx-3 mx-md-auto" action="../send_mail.php" @submit="sendForm()">
    <div class="mb-3">
      <label for="name" class="form-label">お名前 <span class="text-danger"> *</span></label>
      <input type="text" class="form-control" v-model="form_data.name" id="name" name="name" required>
    </div>
    <div class="mb-3">
      <label for="phone" class="form-label">お電話番号<span class="text-danger"> *</span></label>
      <input type="tel" class="form-control" v-model="form_data.phone" id="phone" name="phone" required>
    </div>
    <div class="mb-3">
      <label for="email" class="form-label">メールアドレス<span class="text-danger"> *</span></label>
      <input type="email" class="form-control" v-model="form_data.email" id="email" name="email" required>
    </div>
    <div class="mb-3">
      <label for="message" class="form-label">お問い合わせ内容<span class="text-danger"> *</span></label>
      <textarea class="form-control" v-model="form_data.message" rows="3" id="message" name="message" required></textarea>
    </div>
    <div class="mb-3 text-center">
      <input type="submit" id="submit-button" value="送信">
    </div>
    <div class="text-center" v-if="this.$route.params.sent=='1'">
      <p class="fs-3">お問い合わせいただきありがとうございます。</p>
    </div>
  </form>
  </section>
</template>
<script>
export default {
  data() {
    return {
      sent: false,
      form_data: {
        name : '',
        phone: '',
        email: '',
        message: ''
      },
    }
  },
  mounted() {
    
  },
   methods: {
    view_news_list() {
      this.$router.push('/news_list/');
    },
  },
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";
  #inquiry-form{
    h2{
      text-align: center;
      color: var(--subtitle-color);
      letter-spacing: 10px;
      text-align: center;
    }
    form{
      width: 75%;
      @include media-breakpoint-down(md) {
      width: initial;
      }
    }
    #submit-button{
      background-color: white;
      color: var(--subtitle-color);
      border-color: var(--subtitle-color);
      border-width: 0.125rem;
      border-style: solid;
      box-shadow: none;
      padding: 0.5rem 1.375rem 0.375rem 1.375rem;
      text-align: center;
      text-decoration: none;
      transition: all ease 0.3s;
      border-radius: .25rem;
      &:hover{
        background-color:  var(--subtitle-color);
        color:  white;
        border-color:  white;
        border-color: white
      }
    }
  }
</style>
