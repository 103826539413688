<template>
  <footer class="pt-5 pb-1 px-4">
      <div class="row wrapper mx-auto">
          <p class="col-12 col-md-6 mt-auto _link" @click="view_inquiry()">
              <span class="fw-bolder fs-3">バイオプラスチックフォーラム</span><br>
              <span class="fw-bolder fs-3">事務局</span><br>
              <span class="fs-4">株式会社松井製作所 内</span><br>
          </p>        
          <a class="col-12 col-md-6 my-auto text-center logo hvr-zoom" @click="view_top()"> 
              <img class="w-100" src="@/assets/images/footer-logo.png">
          </a>
          <p class="col-12 text-center">
              © 2023 Bio Plastic Forum 
          </p>
      </div>
  </footer>
</template>

<script>

export default {
  components: {
  },
  methods: {
    // news詳細へ
    view_top() {
      this.$router.push('/');
    },
    // news詳細へ
    view_inquiry() {
      this.$router.push('/inquiry/0');
    },
  },
};
</script>

<style lang="scss">
footer{
  color: white;
  background-image: linear-gradient(var(--gradient-start-header), var(--gradient-end-header));
  .logo img{
    min-width:200px;
    max-width:320px;
  }
  ._link{
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover{
    text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
    }
  }
}
</style>