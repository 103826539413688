<template>
<!-- TOPICS -->
<section id="topics" class="bg-white mb-5">
    <div class="wrapper">
        <h2 class="fw-bolder my-3 fs-1">TOPICS</h2>
        <ul class="p-0 mx-3">
          <li v-for="news in news_list" :key="news" class="fs-4 border-bottom py-3" @click="view_news(news.id)">
            <a>
              <span class="box">■</span>
              <span class="me-3">{{ get_jp_date(news.publishedAt) }}</span>
              <span>{{ news.title }}</span>
            </a>
          </li>
        </ul>
    </div>
</section>
</template>

<script>
import _ from 'lodash';
import {Api} from '@/utiles/ApiConnect';

export default {
  data: function() {
    return {
      news_list:[],
    };
  },

  components: {
  },

  /***
   * ライフサイクル
   */
  mounted: function() {
    this.get_news_list();
  },

  /***
   * メソッド
   */
  methods: {
    get_jp_date (date) {
    // date=createdAt
      let today = new Date(date);
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      date = yyyy + '年' + mm + '月' + dd + '日' ;
      return date;
    },
    get_news_list () {
      Api.client
      .get({endpoint: "news"})
      .then((res) => {
        this.news_list = res.contents;
        if(this.news_list.length>3){
          this.news_list = this.news_list.slice(0, 3);
        }
        console.log(this.news_list)});
      // category,content,createdAt,id,publishedAt,revisedAt,title,updatedAt
    },
    // news詳細へ
    view_news(news_id) {
      this.$router.push('/news/' + news_id);
    },
  },
};
</script>
<style lang="scss">

</style>