<template>
  <div id="topics" class="bg-white mb-5">
    <h1 class="text-center my-3 fw-bold">TOPICS</h1>
    <div id="contens" class="shadow-sm border p-3 mx-3 mx-md-auto">
      <div>
        <!-- タイトル -->
        <h3 class="text-center fs-1 fw-bold">
          {{ news.title }}
        </h3>
        <!-- 公開日 -->
        <p id="date" class="border-bottom" v-html="get_jp_date"></p>
      </div>
      <!-- コンテンツ -->
      <div id="details">
        <div class="content-wrapper">
          <div v-if="!isFullVisible" v-html="shortContent"></div>
          <div v-if="isFullVisible" v-html="news.content"></div>
          <div v-if="!isFullVisible" class="fade-overlay"></div>
          <div v-if="!isFullVisible" class="code-card w-md-100 shadow p-3 border text-center my-3">
            <p><strong>レポートの続きを読むには</strong><br>アンケートご回答後に表示されるパスワードをご入力ください。<br><a href="https://form.run/@bioplastic-forum" target="_blank">アンケートに回答する</a></p>
            <form @submit.prevent="verifyCode" class="d-flex justify-content-center">
              <div class="input-group w-50">
                <input v-model="inputCode" type="text" placeholder="コードを入力" class="form-control">
                <button type="submit" class="btn btn-primary">続きを読む</button>
              </div>
            </form>
            <p v-if="errorMessage" class="text-danger">{{ errorMessage }}</p>
          </div>
          <div v-if="this.news.id == 'ep42egp2q'">
            <div  class="border border-3  p-2 m-2">
              <p>
                <span class="fw-bolder">⼩松 道男</span><br>
                バイオプラスチック フォーラム オーガナイザー<br>
                ⼩松技術⼠事務所 所⻑・ものづくり名⼈
              </p>
              <p class="d-flex">
              アルプス電気を経て、1993年に⼩松技術⼠事務所を設⽴。技術⼠（機械部⾨）。プラスチック射出成形⾦ 型の開発、射出成形システムの研究、ポリ乳酸（PLA）射出成形ビジネスの事業化、超臨界微細発泡射出成 形技術（MuCell）の研究、バイオプラスチック応⽤技術開発等を展開中。欧⽶のプラスチック技術、⾦型 技術に精通している。⽇本、⽶国、ドイツ、フランス、英国、オランダ、スイス、カナダ、中華⼈⺠共和 国、韓国、ブラジルで特許権（特許発明総数305個）、意匠権3件、商標権1件を保有。
              著書に『バイオプラの教科書』（Amazon売れ筋ランキング第１位（資源・エネルギー部⾨）、⽇経Ｂ Ｐ）、『事例でわかるプラスチック⾦型設計の進め⽅』（⽇刊⼯業新聞社）、『プラスチック射出成形⾦型 設計マニュアル』（⽇刊⼯業新聞社）、『はじめての⾦型技術』（共著、⼯業調査会）、『プラスチック射 出成形⾦型』（共著、⽇経BP）、『⾦型が⼀番わかる』（共著、型技術協会編）、『インジェクション⾦ 型の設計2』（CD-R、NTTデータエンジニアリングシステムズ）など多数。
            
              <img src="@/assets/images/06.png" style="height: 250px;
    margin: 0px 40px;">  
            </p>
              <p>
              平成3年技術⼠第⼆次試験史上最年少合格（当時27歳）。 主な受賞は、社団法⼈⽇本機械学会畠⼭賞、公益財団法⼈中部科学技術センター振興賞、⼀般社団法⼈⽇本 合成樹脂技術協会特別会員、LAUNCH:BEYOND WASTE Forum，Innovator of Innovators（NASAジェ ット推進研究所にて、⽶国ベンチャー企業Co-Founderとして）、公益社団法⼈⽇本技術⼠会フェロー、平 成29年度⽂部科学⼤⾂表彰科学技術賞（技術部⾨）受賞、第7回ものづくり⽇本⼤賞内閣総理⼤⾂賞受賞・ ものづくり名⼈の称号を安倍⾸相より授与される。⼀般社団法⼈型技術協会第30回（令和2年度）技術賞受 賞。第１回（令和２年度）気候変動アクション⼤賞受賞（環境⼤⾂）。第10回世界⽔族館会議「Pollution of the Water Planet」基調講演。平成31年政府広報誌We Are Tomodachi、令和2年政府広報誌 Highlighting JAPANインタビュー記事掲載（内閣府⼤⾂官房政府広報室）。令和2年⾸相官邸公式Youtube ⽇本の海洋プラスチックごみ削減取組み事例に出演（再⽣回数１０６万回）、欧⽶でTVCM放送。⼀般社団 法⼈型技術協会会員、⼀般社団法⼈プラスチック成形加⼯学会会員、SPE（Society of Plastics Engineers、USA）会員。福島⾼専⾮常勤講師、元仏Rhône-Alpes州クラスター親善⼤使。K2013国際ゴ ム・プラスチック専⾨⾒本市 Japan Technology Forum（ドイツ）にて基調講演、PLASTIPOLIS FORUM 2014（フランス）ではInternational sectionにて講演。
              </p>
            </div>
            <div class="border border-3  p-2 m-2">
             <h3> 「インターモールド2025東京」にフォーラムメンバーが登壇いたします。</h3>
             <p>４月１６日より東京ビックサイトで開催されます「インタ―モールド2025東京」に、当フォーラムのオーガナイザーならびにメンバーが登壇いたします。ぜひお運びください。
              <br><br>イベント概要
              <br><span class="fw-bolder text-black" style="text-decoration: underline;">INTERMOLD 2025(第36回金型加工技術展)</span>
              <br>会期&nbsp;&nbsp;&nbsp;2025年4月16日（水）〜18日（金）&nbsp;&nbsp;&nbsp;3日間&nbsp;&nbsp;&nbsp;10:00～17:00
              <br>会場&nbsp;&nbsp;&nbsp;東京ビッグサイト（〒135-0063 東京都江東区有明3丁目11-1）
              <br>主催&nbsp;&nbsp;&nbsp;一般社団法人日本金型工業会

              <br><br>登壇予定
              <br><strong>4月16日(水)13:00～14:00</strong> 
              <br><strong style="color:#4f4f4f">バイオプラスチック製品の国内事例と成形技術<span style="color:#1f6bcc"> 小間番号：4-421</span></strong>
              <br>小松技術士事務所 副所長 小松 勝男 氏
              <br>株式会社三義漆器店 代表取締役 曽根 佳弘 氏
              <br>株式会社カミーノ 代表取締役 深澤 幸一郎 氏
              <br>株式会社豊栄工業 代表取締役副社長&nbsp;&nbsp;&nbsp;美和 敬弘 氏
              <br><strong>4月18日(金)13:00～14:00</strong>
              <br><strong>世界のバイオプラスチック活用の現状と展望～米国、欧州の最新動向 小間番号：4-</strong>
              <br>小松技術士事務所&nbsp;&nbsp;&nbsp;所長・ものづくり名人&nbsp;&nbsp;&nbsp;小松 道男 氏

              <br>イベント詳細ならびにお申し込みについては、こちらの公式サイトをご確認ください。
              <br>イベント公式サイト セミナー情報&nbsp;&nbsp;&nbsp;https://www.intermold.jp/seminar/
              </p>
            </div>
          </div>
        </div>
      </div>
      <div id="nav-link" class="text-start mx-3 border-top">
        <a @click="view_news_list()">&lt;&lt; TOPICS一覧</a>
      </div>
    </div>
  </div>
</template>

<script>
import { Api } from '@/utiles/ApiConnect';

export default {
  data() {
    return {
      news: {},
      inputCode: "",
      isFullVisible: true,
      errorMessage: "",
      correctCode: "Bio2025", // Ganti dengan kode yang Anda inginkan
    };
  },
  computed: {
    get_jp_date() {
      let date = this.news.publishedAt ? new Date(this.news.publishedAt) : false;
      return date ? `${date.getFullYear()}年${String(date.getMonth() + 1).padStart(2, '0')}月${String(date.getDate()).padStart(2, '0')}日` : "<br>";
    },
    shortContent() {
      if (this.news.id == 'ep42egp2q'){
        if (!this.news.content) return "";
        let plainText = this.news.content.substring(0, 2050); // Hapus HTML tags
        return plainText;
      }else{
        return this.news.content;
      }
      }
  },
  mounted() {
    this.getNews();
  },
  watch: {
    'news.id'(newId) {
      if (newId == 'ep42egp2q') {
        this.isFullVisible = false;
      }
    }
  },
  methods: {
    view_news_list() {
      this.$router.push('/news_list/');
    },
    getNews() {
    Api.client
        .get({ endpoint: "news", contentId: this.$route.params.id })
        .then((res) => {
            this.news = res;
            // Apply styles to images with alt="profile" after news data is loaded
            this.$nextTick(() => {
                document.querySelectorAll('img[alt="profile"]').forEach(img => {
                    img.style.float = "right";
                    img.style.margin = "25px 40px 0px 40px";
                    img.style.width = "200px";
                });
            });
        });
    },

    verifyCode() {
      if (this.inputCode === this.correctCode) {
        this.isFullVisible = true;
        this.errorMessage = "";
        this.$nextTick(() => {
                document.querySelectorAll('img[alt="profile"]').forEach(img => {
                    img.style.float = "right";
                    img.style.margin = "25px 40px 0px 40px";
                    img.style.width = "200px";
                });
            });
      } else {
        this.errorMessage = "コードが違います。再試行してください！";
      }
    }
  }
};
</script>

<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";

#topics {
  background-color: var(--bg-1);
  h1 {
    color: var(--subtitle-color);
    letter-spacing: 10px;
    text-align: center;
  }
  #contens {
    width: 75%;
    @include media-breakpoint-down(md) {
      width: initial;
    }
    #date {
      color: gray;
    }
    #details {
      position: relative;
      .content-wrapper {
        position: relative;
        max-height: 2600px;
        overflow: hidden;
      }
      .fade-overlay {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 50px;
        background: linear-gradient(to bottom, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
      }
      .code-card {
        margin: auto;
        background-color: #f8f9fa;
        border-radius: 8px;
      }
      h3 {
        border-left: solid 4px var(--subtitle-color);
        padding-left: 4px;
      }
      h2 {
        letter-spacing: initial !important;
        border-left: solid 4px var(--subtitle-color);
        padding-left: 5px;
        text-align: start;
      }
      a {
        color: blue;
        text-decoration: underline;
      }
      img {
        height: auto;
        max-width: 420px;
      }
    }
    #nav-link a {
      color: blue !important;
      text-decoration: underline;
    }
  }
}
</style>
