<template>
  <header class="row text-start p-3 shadow">
      <div class="row px-md-5">
          <a class="col-12 col-md text-center logo" @click="view_top()"> 
              <img src="@/assets/images/header-logo.png">
          </a>
          <a class="col-12 col-md text-center my-auto pt-2" @click="view_inquiry()"> 
                  <img src="@/assets/icon/mail.png" style="width:35px;"> 
              <span class="col text-start p-0 mt-auto ps-2 fs-5"> 問い合わせ</span>
          </a>
      </div>
  </header>
</template>

<script>
import {ref} from 'vue';
import _ from 'lodash';

export default {
  data: function() {
    return {
      member_info: [],
    };
  },

  components: {
  },

  computed: {

  },

  setup() {
    const collapse1 = ref(false);
    return {
      collapse1,
    };
  },

  mounted: function() {

  },

  methods: {
    // news詳細へ
    view_inquiry() {
      this.$router.push('/inquiry/0');
    },
    // news詳細へ
    view_top() {
      this.$router.push('/');
    },
  },
};
</script>

<style lang="scss">
header{
  background-image: linear-gradient(var(--gradient-start-header), var(--gradient-end-header));
  z-index: 99999;
  .logo img{
    width:180px;
  }
  a{
    color: white;
    text-decoration: none;
    cursor: pointer;
    transition: all ease 0.3s;
    &:hover{
      color: white;
      letter-spacing: 1px;
    }
  }
}

</style>