
const { createClient } = require('microcms-js-sdk');
const SERVICE = "bioplastic-forum";
const API_KEY = "ud1EtkrDQv7co8DSgUIQJTevuLwZvjI3BjD5";
const client = createClient({ serviceDomain: SERVICE, apiKey: API_KEY });
class ApiConnect {
	//コンストラクタ
	constructor() {
		this.client = createClient({ serviceDomain: SERVICE, apiKey: API_KEY });
	}
	// params = { limit: 10, offset: 0, orders: '-createdAt' }
}
const Api = new ApiConnect();


export {Api}