<template>
<!-- hero area -->
<div id="hero-area" class="d-flex align-items-center">
    <div>
        <img src="@/assets/images/banner-logo.png">
    </div>
</div>
<!-- info -->
<!-- <section class="wrapper mb-3">
    <a class="hvr-zoom" href="https://webinarweek.net/ww/202301bioplastic/" target="_blank">
        <img src="@/assets/images/ww-banner.png" class="w-100 px-2">
    </a>
    <span v-if=" new Date() <= new Date(2023,1,8) " class="mb-3 fs-5">バイオプラスチックの基礎から市場状況、成形加⼯までいま押さえるべき最新情報が集結！<br>招待コードでまずは無料受講予約！<br>
      <span class="fw-bolder">【ご招待コード】Y1233416-b</span>
    </span>
</section> -->
<!-- TOPICS -->
<section id="topics">
    <div class="wrapper">
        <h2 class="fw-bolder my-3 fs-1">TOPICS</h2>
        <ul class="px-4 me-2">
          <!-- v-for="news in news_list" :key="news"  -->
            <!-- <li v-for="news in news_list" :key="news" class="fs-4 border-bottom py-3" @click="view_news(news.id)"> -->
              <li v-for="news in news_list" :key="news" 
                      :class="['fs-4', 'border-bottom', 'py-3', news.id === 'ep42egp2q' ? 'd-none' : '']" 
                      @click="view_news(news.id)">
                <a>
                  <span class="box">■</span>
                  <span class="me-3">{{ get_jp_date(news.publishedAt) }}</span><br>
                  <span>{{ news.title }}</span>
                  <!-- <span class="me-3">2023年1月20日</span>
                  <span>WEBサイトを公開いたしました</span> -->
                  <!-- 2023年1月20日WEBサイトを公開いたしました -->
                </a>
                <!-- @click="view_news(1)" -->
            </li>
            <!-- <li class="fs-4 my-2">
                <a >
                  <span class="box">■</span>
                  <span class="me-3">2023年1月23日</span>
                  <span> 企業合同ウェビナー「バイオプラスチックによるカーボンニュートラルへの挑戦」視聴予約受付を開始しました</span>
                </a>
            </li> -->
        </ul>
        <div id="nav-link" class="text-end mx-3">
            <a @click="view_news_list()">MORE</a>
            
        </div>
    </div>
</section>
<!-- Title -->
<section id="title">
    <h2>BIO PLASTIC FORUM</h2>
</section>
<!-- bioplastic is 01 -->
<section id="bioplastic-section" class="row section-wrapper mb-5">
    <div id="section-image" class="col-12 col-lg-5 p-0 text-center">
        <img src="@/assets/images/01.png">
    </div>
    <div id="section-details" class="col pe-md-5 row">
        <div class="col-12 col-lg-11">
            <h2>01</h2>
            <h3 class="mt-5 mb-4">バイオプラスチックとは</h3>
            <p class="fs-4">植物などの再⽣可能な有機資源を原料とするバイオマスプラスチックと、微⽣物等の働きで最終的に⼆酸化炭素と⽔にまで分解する⽣分解性プラスチックの総称です。<br>（出典：環境省）
            </p>
        </div>
        <div class="col-12 col-lg-1">
        </div>
    </div>
</section>
<!-- bioplastic definition 02 -->
<section id="bioplastic-section" class="row section-wrapper mb-5 sm-reverse-col">
    <div id="section-details" class="col pe-md-5 row right-details">
        <div class="col-12 col-lg-1">
        </div>
        <div class="col-12 col-lg-11">
            <h2>02</h2>
            <h3 class="mt-5 mb-4">バイオマスプラスチックの<br>定義</h3>
            <p class="fs-4">原料として再⽣可能な有機資源由来の物質を含み、化学的⼜は⽣物学的に合成することにより得られる⾼分⼦材料」です。（化学的に未修飾な天然有機⾼分⼦材料は除く）<br>（⽇本バイオプラスチック協会（JBPA）定義）
            </p>
        </div>
    </div>
    <div id="section-image" class="col-12 col-lg-5 p-0 text-center">
        <img src="@/assets/images/02.png">
    </div>
</section>
<!-- bioplastic impact -->
<section id="bioplastic-impact" class="row section-wrapper mb-5">
    <div>
        <p> バイオプラスチックは、従来の化⽯資源由来で⾮分解性のプラスチックを代替することにより環境負荷を低減させる事が可能です
        </p>
    </div>
</section>
<!-- classification of bioplastic 03 -->
<section class="row section-wrapper mb-5">
    <div id="section-image" class="col-12 col-lg-5 p-0 text-center">
        <img src="@/assets/images/03.png">
    </div>
    <div id="section-details" class="col row">
        <div class="col-12 col-lg-11">
            <h2>03</h2>
            <h3 class="mt-5 mb-4">バイオプラスチックの分類</h3>
            <p class="fs-4">バイオプラスチックは、原料、製法、化学構造や機能は様々であり、それぞれの特徴を正しく理解して⽬的や解決したい環境問題に応じて適切な⽤途で使⽤することが重要となります。
            </p>
        </div>
        <div class="col-12 col-lg-1">
        </div>
    </div>
</section>
<!-- classification details -->
<section id="classification-details" class=" mb-5">
    <div class="row wrapper justify-content-center">
        <div id="biomass-plastic" class="col-12 col-md-4 mx-2 mb-3 mb-md-0 wrapper ">
            <div id="title">
                <h4 class="px-5 py-3 fs-5 fw-bolder text-center">バイオマスプラスチック</h4>
            </div>
            <div id="desc-wrapper">
                <p>原料として再⽣可能な有機資源由来の物質を含み、化学的⼜は⽣物学的に合成することにより得られる⾼分⼦材料」（化学的に未修飾な天然有機⾼分⼦材料は除く）
                </p>
                <p id="pil" class="mt-1">性質と特徴</p>
                <p id="description" class="p-2">⾮分解性・バイオマスプラスチック<br>（バイオポリエチレン等）<br>〇カーボンニュートラル<br>△海洋流出でマイクロプラスチック化する
                </p>
                <p id="description" class="p-2">バイオマス由来・⽣分解性プラスチック<br>（PLA等）<br>〇カーボンニュートラル<br>〇陸上で⽣分解させマイクロプラスチック<br>化を回避できる
                </p>
            </div>
        </div>
        <div id="biodegradable-plastic" class="col-12 col-md-4 mx-2 ">
            <div id="title">
                <h4 class="px-5 py-3 fs-5 fw-bolder text-center">⽣分解性プラスチック</h4>
            </div>
            <div id="desc-wrapper">
                <p id="pil" class="mt-1">性質と特徴</p>
                <p id="description" class="p-2">化⽯資源由来・⽣分解性プラスチック<br>（PBAT等）<br>△⽣分解や燃焼時に化⽯資源由来のCO2が増加する<br>〇陸上で⽣分解させマイクロプラスチック化を回避できる
                </p>
                <p id="description" class="p-2">バイオマス由来・⽣分解性プラスチック<br>（PLA等）<br>〇カーボンニュートラル<br>〇陸上で⽣分解させマイクロプラスチック<br>化を回避できる
                </p>
            </div>
        </div>
    </div>
</section>
<!-- bioplastic application 04 -->
<section id="bioplastic-application" class="row section-wrapper mb-5 sm-reverse-col">
    <div id="section-details" class="col pe-md-5 row right-details">
        <div class="col-12 col-lg-1">
        </div>
        <div class="col-12 col-lg-11">
            <h2>04</h2>
            <h3 class="mt-5 mb-4">バイオプラスチックの⽤途</h3>
            <p class="fs-4">バイオプラスチックは、レジ袋や農業⽤シート、使い捨てスプーンやフォーク等への利⽤に留まっていましたが、近年は新しい成形技術や樹脂の開発や改良により多様な利⽤⽅法や⾼付加価値な商品を⽣み出すことが出来るようになってきています。<br>・耐熱性を持つ安全なベビー⽤⾷器<br>・透明薄⾁のシャンパングラス<br>・古紙粉とのブレンドにより⾼付加価値で耐久性のあるタンブラーなど
            </p>
        </div>
    </div>
    <div id="section-image" class="col-12 col-lg-5 p-0 text-center">
        <img src="@/assets/images/04.png">
    </div>
</section>
<!-- learn bioplastic 05 -->
<section class="row section-wrapper mb-5">
    <div id="section-image" class="col-12 col-lg-5 p-0 text-center">
        <img src="@/assets/images/05.png" style="height:100%;width:auto;">
    </div>
    <div id="section-details" class="col row">
        <div class="col-12 col-lg-11">
            <h2>05</h2>
            <h3 class="mt-5 mb-4">バイオプラスチックについて学ぶ</h3>
            <p class="fs-4">⼩松道男著：脱炭素時代のグリーン材料『バイオプラの教科書』（⽇経BP）
            </p>
            <p class="fs-4 text-center text-md-start">
            <a id="amazon-button" href="https://amzn.asia/d/bdyp5qr" target="_blank" class="rounded-3 fs-5 py-2 px-5">アマゾンで購⼊する
            </a>
            </p>
        </div>
        <div class="col-12 col-lg-1">
        </div>
    </div>
</section>
<!-- inquiry -->
<section class="row mb-5 section-wrapper ">
    <div id="inquiry-button" class="text-center">
      <a href="" @click="view_inquiry()">
        <div>お問合せフォーム</div>
      </a>
    </div>      
    <div id="inquiry-details" class="mx-auto text-start fs-5">
        <p>バイオプラスチックの活⽤は私たちにご相談ください。商品企画から製造技術についてまで、あらゆるご相談を承っています。
        </p>
    </div>
</section>
<!-- bioplastic forums is -->
<section id="bioplastic-forum-is" class="row section-wrapper mb-5">
    <div class="col-12 text-center">
        <h2 class="py-4">バイオプラスチックフォーラムとは</h2>
    </div>
    <div class="col pe-5 row">
        <div class="col-lg-4">
        </div>
        <div id="bioplastic-forum-is-desc" class="col-12 col-md-8 fs-5">
            <p>サーキュラーエコノミー(循環型経済)、気候変動など世界的視野でプラスチック産業の在り⽅を考えることが重要になってきています。<br>共通認識を抱いた⽅々が意⾒を交換しリアリティのある企画、チャレンジ、イノベーションへ駒を進める機会を作ることからまず始めてみませんか?という呼びかけに共感する⽅々が集うプラットフォームを作り試験的な運⽤を始めることにしました。<br>
            </p>
            <p class="text-end">バイオプラスチックフォーラム<br>オーガナイザー⼩松道男
            </p>
        </div>
    </div>
    <div class="col-lg-5 p-0 h-100">
    </div>
</section>
<!-- forum Organizer -->
<section id="forum-organizer" class="mb-5">
    <div id="forum-organizer-wrapper" class="row">
        <div id="section-details" class="col row ">
            <div class="col-12 col-md-6">
                <h2>06</h2>
                <h3 class="mt-5 mb-4">⼩松道男<span class="fs-6">こまつみちお</span></h3>
                <p class="fs-5">バイオプラスチック フォーラム オーガナイザー <br>⼩松技術⼠事務所 所⻑<br>ものづくり名⼈
                </p>
            </div>
            <div id="section-image" class="col-12 col-md-6 p-0 ms-auto text-md-end text-center">
                <img src="@/assets/images/06.png" class="w-md-100 w-80">
            </div>
            <div id="forum-organizer-details" class="col-12 fs-5 mt-5">
                <p>アルプス電気を経て、1993年に⼩松技術⼠事務所を設⽴。技術⼠（機械部⾨）。プラスチック射出成形⾦型の開発、射出成形システムの研究、ポリ乳酸（PLA）射出成形ビジネスの事業化、超臨界微細発泡射出成形技術（MuCell）の研究、バイオプラスチック応⽤技術開発等を展開中。欧⽶のプラスチック技術、⾦型技術に精通している。⽇本、⽶国、ドイツ、フランス、英国、オランダ、スイス、カナダ、中華⼈⺠共和国、韓国、ブラジルで特許権（特許発明総数305個）、意匠権3件、商標権1件を保有。
                </p><br>
                <p>著書に『バイオプラの教科書』（Amazon売れ筋ランキング第１位（資源・エネルギー部⾨）、⽇経ＢＰ）、『事例でわかるプラスチック⾦型設計の進め⽅』（⽇刊⼯業新聞社）、『プラスチック射出成形⾦型設計マニュアル』（⽇刊⼯業新聞社）、『はじめての⾦型技術』（共著、⼯業調査会）、『プラスチック射出成形⾦型』（共著、⽇経BP）、『⾦型が⼀番わかる』（共著、型技術協会編）、『インジェクション⾦型の設計2』（CD-R、NTTデータエンジニアリングシステムズ）など多数。
                </p><br>
                <p>平成3年技術⼠第⼆次試験史上最年少合格（当時27歳）。主な受賞は、社団法⼈⽇本機械学会畠⼭賞、公益財団法⼈中部科学技術センター振興賞、⼀般社団法⼈⽇本合成樹脂技術協会特別会員、LAUNCH:BEYOND WASTE Forum，Innovator of Innovators（NASAジェット推進研究所にて、⽶国ベンチャー企業Co-Founderとして）、公益社団法⼈⽇本技術⼠会フェロー、平成29年度⽂部科学⼤⾂表彰科学技術賞（技術部⾨）受賞、第7回ものづくり⽇本⼤賞内閣総理⼤⾂賞受賞・ものづくり名⼈の称号を安倍⾸相より授与される。⼀般社団法⼈型技術協会第30回（令和2年度）技術賞受賞。第１回（令和２年度）気候変動アクション⼤賞受賞（環境⼤⾂）。第10回世界⽔族館会議「Pollution of the Water Planet」基調講演。平成31年政府広報誌We Are Tomodachi、令和2年政府広報誌Highlighting JAPANインタビュー記事掲載（内閣府⼤⾂官房政府広報室）。令和2年⾸相官邸公式Youtube ⽇本の海洋プラスチックごみ削減取組み事例に出演（再⽣回数１０６万回）、欧⽶でTVCM放送。⼀般社団法⼈型技術協会会員、⼀般社団法⼈プラスチック成形加⼯学会会員、SPE（Society of Plastics Engineers、USA）会員。福島⾼専⾮常勤講師、元仏Rhône-Alpes州クラスター親善⼤使。K2013国際ゴム・プラスチック専⾨⾒本市 Japan Technology Forum（ドイツ）にて基調講演、PLASTIPOLIS FORUM 2014（フランス）ではInternational sectionにて講演。
                </p>
            </div>
        </div>
    </div>
</section>
<!-- partner -->
<section id="partner" class="mb-md-3 wrapper">
    <div class="px-md-5 px-3">
        <h2 class="fw-bold my-3">私たちはバイオプラスチックフォーラムの趣旨に賛同しています
        </h2>
    </div>
    <div class="row px-3">
      <div  v-for="company in company_list" :key="company" class="col-6 col-md-4 my-auto pb-4 mx-auto text-center">
        <a :href="''+company.link" class="hvr-zoom" target="_blank">
            <!-- <img :src="require(`@/assets/images/`+company.image.url)" class="w-75 mx-auto p-2 "> -->
            <img :src="company.image.url" class="w-75 mx-auto p-2 ">

        </a> 
      </div>
    </div>
</section>
</template>

<script>
import _ from 'lodash';
import '@/utiles/jquery.simpleTicker';
import {Api} from '@/utiles/ApiConnect';

export default {
  data: function() {
    return {
      news_list:[],
      company_list:[
        // {
        //   company_name:"株式会社カミーノ",
        //   image:{url:"株式会社カミーノ.png"},
        //   link:"https://ca-mi-no.jp/",
        // },
        // {
        //   company_name:"株式会社サンエイプラテック",
        //   image:{url:"株式会社サンエイプラテック.png"},
        //   link:"http://www.saneipla.jp/",
        // },
        // {
        //   company_name:"Total Energies Corbion",
        //   image:{url:"TotalEnergies Corbion.png"},
        //   link:"https://www.totalenergies-corbion.com/",
        // },
        // {
        //   company_name:"日精樹脂工業株式会社",
        //   image:{url:"日精樹脂工業株式会社.png"},
        //   link:"https://www.nisseijushi.co.jp/",
        // },
        // {
        //   company_name:"双葉電子工業株式会社",
        //   image:{url:"双葉電子工業株式会社.png"},
        //   link:"https://www.futaba.co.jp/",
        // },
        // {
        //   company_name:"株式会社ペッカー精工",
        //   image:{url:"株式会社ペッカー精工.png"},
        //   link:"http://www.pecker-seiko.com/",
        // },
        // {
        //   company_name:"株式会社豊栄工業",
        //   image:{url:"株式会社豊栄工業.png"},
        //   link:"https://www.hoic.co.jp/",
        // },
        // {
        //   company_name:"株式会社松井製作所",
        //   image:{url:"株式会社松井製作所.png"},
        //   link:"https://matsui.net/",
        // },
      ]
    };
  },

  components: {
  },

  mounted: function() {
    this.get_news_list();
    this.get_company_list();
  },

  methods: {
    get_jp_date (date) {
    // date=createdAt
      let today = new Date(date);
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
      const yyyy = today.getFullYear();
      date = yyyy + '年' + mm + '月' + dd + '日' ;
      return date;
    },
    get_news_list () {
      Api.client
      .get({endpoint: "news"})
      .then((res) => {
        this.news_list = res.contents;
        if(this.news_list.length>3){
          this.news_list = this.news_list.slice(0, 4);
        }
        console.log(this.news_list)});
      // category,content,createdAt,id,publishedAt,revisedAt,title,updatedAt
    },
    get_company_list () {
      Api.client
      .get({endpoint: "partners"})
      .then((res) => {this.company_list = res.contents;console.log(this.company_list)});
      // category,content,createdAt,id,publishedAt,revisedAt,title,updatedAt
    },
    view_news_list() {
      this.$router.push('/news_list/');
    },
    view_news(news_id) {
      this.$router.push('/news/' + news_id);
    },
    view_inquiry() {
      this.$router.push('/inquiry/0');
    },
    
  },
};
</script>
<style lang="scss">
@import "../../node_modules/mdb-vue-ui-kit/src/scss/index.free";
/* hero-area */
#hero-area{
  height: 600px;
  background-image: url("@/assets/images/hero-area-bg.png");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  img{
    width: 65%;
    margin-left: 8%;
  }
  @include media-breakpoint-down(md) {
    height: 300px;
  }
}
/* topics */
#topics { 
  min-height: 350px;
  background-color: var(--bg-1);
  h2{
    color: var(--subtitle-color);
    letter-spacing: 10px;
    text-align: center;
  }
  ul {
    list-style-type: none;
    .box{
      color: var(--subtitle-color);
    }
    li{
      transition: all ease 0.3s;
      cursor: pointer;
      &:hover{
        background-color: var(--gradient-start-header) !important;
      }
    }
  }
  a{
    color: black;
    text-decoration: none;
    cursor: pointer;
  }
  #nav-link{
  a{
    color:gray;
    transition: all ease 0.3;
    font-weight: bold;
    &:hover{
      color: var(--subtitle-color) !important;
    }
  }
  }
}   
/* title */
#title {
  h2{
    text-align: center;
    color: var(--bg-title-color);
    font-family: "Yu Mincho", Times, serif !important;
    font-size: 140px;
    letter-spacing: 5px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    justify-content: center;
    display: flex;
  }
}
/* common section part */
.sm-reverse-col{
  @include media-breakpoint-down(lg) {
    flex-direction: column-reverse;
  }
}
#section-details{
  background-color: var(--bg-2);
  width: 70%;
  padding: 2.5rem 0px 0px 2.5rem;
  margin: 0;
  @include media-breakpoint-down(lg) {
   padding: 1rem 0px 0px 1rem;
  }
  &.right-details{
    @include media-breakpoint-down(lg) {
      width: 100%;
    }
  }
  .left-details{
    margin-left: 30%;
  }
  h2{
    font-family: serif !important;
    border-bottom: solid var(--sub-title-border);
    letter-spacing: 2px;
    width: 20%;
    font-weight: 500;
    font-size: 52px;
  }
  h3{
    font-family: "Yu Mincho", Times, serif !important;
    font-weight: 600;
    font-size: 45px;
  }
  p{
    font-family: "Yu Mincho", Times, serif !important;
    line-height: 2;
  }
}
#section-image {
  img{
    // height: auto;
    // width: 100%;
    width: auto;
    height: 100%;
  }
  @include media-breakpoint-down(lg) {
    height: 400px;
    img{
      width: 100%;
      height: auto;
    }
  }
}
#bioplastic-section{
  min-height: 500px;
}
#amazon-button{
  background-color: white;
  color: gray;
  border-color: gray;
  border-width: 0.125rem;
  border-style: solid;
  box-shadow: none;
  padding: 0.5rem 1.375rem 0.375rem 1.375rem;
  text-align: center;
  text-decoration: none;
  transition: all ease 0.3s;
  border-radius: .25rem;
  &:hover{
    background-color:  rgba(1,1,1,0.15);
    color:  black;
    border-color:  black;
  }
}
/* bioplastic impact */
#bioplastic-impact{
  height: 400px;
  background-image: url("@/assets/images/bioplastic-impact.png");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  display: flex;
  align-items: center;
  justify-content: center;
  p{
    color: white;
    font-size: 36px;
    padding: 0px 20% 0px 20%;  
    font-family: "Yu Mincho" !important;
    line-height: 2;
  }
  @include media-breakpoint-down(md) {
    p{
      color: white;
      font-size: 28px;
      padding: 0px 5% 0px 5%;  
      font-family: "Yu Mincho" !important;
      line-height: 2;
    }
  }
}
/* classification-details */
#classification-details{
  background-color: var(--plastic-bg);
  padding: 30px;
  p{
      line-height: 1.7;
  }
  #pil{
    border-radius: 40px;
    text-align: center;
    font-weight: 600;
    padding: 12px 0px 12px 0px;
  }
}
#biomass-plastic{
  h4{
    background-color: var(--plastic-biomassa-bg);
  } 
  #desc-wrapper{
    background-color: white;
    padding:10px;
  }
  #pil{
    background-color: var(--plastic-biomassa-bg);
  }
  #description{
    background-color: var(--plastic-biomassa-bg-light);
  }
}
#biodegradable-plastic {
  #desc-wrapper{
    background-color: white;
    padding:10px;
  }
  h4{
    background-color: var(--plastic-biodegradabel-bg);
  }
  #pil{
    background-color: var(--plastic-biodegradabel-bg);
  }
  #description{
    background-color: var(--plastic-biodegradabel-bg-light);
  }
}
/*  inquiry */
#inquiry-button {
  a{
    color: #fff;
    padding: 15px;
    font-size: 2.5rem;
    font-weight: bold;
    margin: 2rem 0;
    display: inline-block;
    align-items: center;
    border-radius: 28px;
    background-image: -webkit-gradient(linear, left top, right top, from(var(--gradient-start-btn )), to(var(--gradient-end-btn )));
    background-image: -webkit-linear-gradient(left, var(--gradient-start-btn ) 40%, var(--gradient-end-btn ) 100%);
    background-image: linear-gradient(to bottom, var(--gradient-start-btn ) 40%, var(--gradient-end-btn ) 100%);
    -webkit-box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    box-shadow: 0 5px 10px rgb(0 0 0 / 10%);
    text-decoration: none;
    line-height: 2.7rem;
    width: 60%;
    transition: all ease 0.3s;
    @include media-breakpoint-down(md) {
      width: 90%;
      font-size: 1.8rem;
    }
    &:hover{
      -webkit-transform: translate(0, 2px);
      transform: translate(0, 2px);
      color: #fff;
      -webkit-box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
      box-shadow: 0 8px 15px rgba(0, 0, 0, .2);
    }
  }
  div {
    border: 2px solid #fff;
    border-radius: 20px;
    padding: 14px;
  }
}
#inquiry-details{
  width: 50%;
  @include media-breakpoint-down(md) {
      width: 90%;
  }
}
/* bioplastic forum is */
#bioplastic-forum-is{
  height: 650px;
  background-image: url("@/assets/images/bioplastic-bg.png");
  background-repeat:no-repeat;
  background-size:cover;
  background-position:center;
  h2{
    font-family: "Yu Mincho", Times, serif !important;
    color:white;
    font-size: 3.2rem;
    font-weight: bolder;
    text-shadow: 2px 4px 4px rgba(0, 0, 0, 0.2); 
  } 
  p{
    font-family: "Yu Mincho", Times, serif !important;
    color:white;
    line-height: 2;
  }
  @include media-breakpoint-down(md) {
    background-position:cnter;
    height: initial;
    h2{
      font-size: 2.5rem;
    } 
  }
  @include media-breakpoint-down(md) {
    #bioplastic-forum-is-desc{
      background-color: rgba(0,0,0,0.5);
      margin-bottom: 20px;
      box-shadow: 2px 2px 5px rgb(0,0,0,0.7);
    }
  }

}
/* forum-organizer */
#forum-organizer {
  background-color: var(--bg-2);
  img{
    width: 300px;
  }
  @include media-breakpoint-down(md) {
    img{
      width: auto !important;
      height: 100%!important;
      box-shadow: 0px 5px 5px rgba(0, 0, 0, 0.5);
    }
  }
  #section-details p{
    font-family: "meiryo" !important;
    line-height: initial;
  }
  #forum-organizer-wrapper {
    max-width: 1000px;
    margin: 0 auto 0 auto;
  }
  #forum-organizer-details {
    p{
      padding-right: 10px;
      line-height: 2 !important;
    }
  }
}
#partner{
  &.wrapper{
    max-width: 1000px !important;
  }
  h2{
    font-family: "Yu Mincho", Times, serif !important;
    text-align: center;
    font-size: 2.5rem;
    @include media-breakpoint-down(md) {
      font-size: 1.5rem;
    }
  }
}
</style>
